import { Share } from "@capacitor/share";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../../types";

interface ShareReferralLinkOptions {
  signUpReferralCodeLink: string;
  referralCode: string;
  variant: string;
}
export async function shareReferralLink(options: ShareReferralLinkOptions) {
  const { signUpReferralCodeLink, referralCode, variant } = options;
  const isCapacitorSupported = isCapacitorPlatform();
  const content = {
    title: "You should check out Clipboard Health!",
    text: "Hey there 👋 sign up to Clipboard Health so you can start posting and filling up shifts with workers like me!",
    url: signUpReferralCodeLink,
  };
  if (isCapacitorSupported) {
    try {
      const shareResult = await Share.share(content);
      logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
        action: WorkplaceReferralAction.REFERRAL_LINK_SHARED,
        component: WorkplaceReferralComponent.SHARE_REFERRAL_LINK_DIALOG,
        referralCode,
        variant,
        link: signUpReferralCodeLink,
        isMobile: isCapacitorSupported,
        shareResult,
      });
    } catch (error) {
      // When user closes the native share dialog manually, it will throw an error
      logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
        action: WorkplaceReferralAction.REFERRAL_LINK_SHARED,
        component: WorkplaceReferralComponent.SHARE_REFERRAL_LINK_DIALOG,
        referralCode,
        variant,
        link: signUpReferralCodeLink,
        isMobile: isCapacitorSupported,
        error: String(error),
      });
    }
  } else {
    const mailtoLink = `mailto:?subject=${content.title}&body=${content.text} \n${content.url}`;
    window.open(mailtoLink, "_blank", "noopener,noreferrer");
    logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
      action: WorkplaceReferralAction.REFERRAL_LINK_SHARED,
      component: WorkplaceReferralComponent.SHARE_REFERRAL_LINK_DIALOG,
      variant,
      referralCode,
      link: signUpReferralCodeLink,
      isMobile: isCapacitorSupported,
    });
  }
}
