import { type ReferralCode } from "../types";

export function getLatestReferralCode(referralCodes?: ReferralCode[]) {
  if (!referralCodes || referralCodes.length === 0) {
    return undefined;
  }

  // eslint-disable-next-line unicorn/prefer-at
  const latestReferralCodeItem = referralCodes.slice(-1)[0];
  return latestReferralCodeItem.referralCode;
}
