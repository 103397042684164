import { Clipboard } from "@capacitor/clipboard";
import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Card, CardContent, IconButton, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { useWorkerToWorkplaceAffiliateExperiment } from "../../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../../types";
import { getWorkerToWorkplaceVariantName } from "../../utils/getWorkerToWorkplaceVariantName";

interface WorkplaceReferralsListCardsProps {
  workplaceName: string;
  workplaceUserName: string;
  workplaceUserEmail: string;
  workplaceAddress: string;
  bonusAmount?: number;
}

export function WorkplaceReferralsListCard(props: WorkplaceReferralsListCardsProps) {
  const { showSuccessToast } = useToast();
  const { workplaceName, workplaceUserName, workplaceUserEmail, workplaceAddress, bonusAmount } =
    props;
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  return (
    <Card
      variant="outlined"
      sx={{
        padding: 0,
      }}
    >
      <CardContent>
        <Title component="h3">{workplaceName}</Title>
        <Text variant="body2">{workplaceAddress}</Text>
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <PersonIcon fontSize="small" />
            <Text variant="subtitle1">{workplaceUserName}</Text>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <EmailIcon fontSize="small" />
            <Text variant="subtitle1">{workplaceUserEmail}</Text>
            <IconButton
              onClick={async () => {
                await Clipboard.write({
                  string: workplaceUserEmail,
                });
                logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL_STATS, {
                  action: WorkplaceReferralAction.REFERRAL_STATS_EMAIL_COPIED,
                  variant: getWorkerToWorkplaceVariantName(
                    isWorkerToWorkplaceAffiliateProgramEnabled
                  ),
                  component: WorkplaceReferralComponent.WORKPLACE_REFERRAL_STATS_LIST_ITEM,
                });
                showSuccessToast("Email copied successfully");
              }}
            >
              <ContentCopyIcon
                fontSize="small"
                sx={(theme) => ({
                  fontSize: theme.typography.body2.fontSize,
                  marginLeft: 1,
                })}
              />
            </IconButton>
          </Stack>
          {isDefined(bonusAmount) ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <AttachMoneyIcon fontSize="small" />
              <Text variant="subtitle1">{bonusAmount} Earned</Text>
            </Stack>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
}
