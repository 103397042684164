import { RootPaths } from "@src/appV2/App/paths";

import { WORKPLACE_REFERRAL_SCREEN_NAME, WORKPLACE_REFERRAL_STATS_SCREEN_NAME } from "./constants";

export const WORKPLACE_REFERRALS_PATH = "workplace-referrals";
export const WORKPLACE_REFERRALS_STATS_PATH = `${WORKPLACE_REFERRALS_PATH}/stats`;
export const WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH = `${RootPaths.HOME}/account/${WORKPLACE_REFERRALS_PATH}`;
export const WORKPLACE_REFERRALS_STATS_WITH_ACCOUNTS_PATH = `${RootPaths.HOME}/account/${WORKPLACE_REFERRALS_STATS_PATH}`;

export const WorkplaceReferralRouterPath = {
  WORKPLACE_REFERRAL_PATH: WORKPLACE_REFERRALS_WITH_ACCOUNTS_PATH,
  WORKPLACE_REFERRALS_STATS_PATH: WORKPLACE_REFERRALS_STATS_WITH_ACCOUNTS_PATH,
};

export const WorkplaceReferralRouterPathInfo = {
  WORKPLACE_REFERRAL_PATH: {
    name: WORKPLACE_REFERRAL_SCREEN_NAME,
  },
  WORKPLACE_REFERRALS_STATS_PATH: {
    name: WORKPLACE_REFERRAL_STATS_SCREEN_NAME,
  },
};
