import { InternalLink, Text } from "@clipboard-health/ui-react";
import { ListItem, ListItemText, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { InfoTooltip } from "@src/appV2/lib/Tooltip/InfoTooltip";

import { useWorkerToWorkplaceAffiliateExperiment } from "../../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../../types";
import { getWorkerToWorkplaceVariantName } from "../../utils/getWorkerToWorkplaceVariantName";

interface ReferralStatItemProps {
  title: string;
  formattedValue: string;
  infoText: string;
  formattedValueLinkPath: string;
}

export function ReferralStatItem(props: ReferralStatItemProps) {
  const { title, formattedValue, infoText, formattedValueLinkPath } = props;
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  return (
    <ListItem
      sx={{
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <ListItemText
        primary={
          <Stack alignItems="center" spacing={1}>
            <Stack direction="row" alignItems="center">
              <Text bold>{title}</Text>
              <InfoTooltip
                infoText={infoText}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                    action: WorkplaceReferralAction.REFERRAL_STATS_TOOLTIP_CLICKED,
                    component: WorkplaceReferralComponent.REFERRAL_DATA_SECTION,
                    variant: getWorkerToWorkplaceVariantName(
                      isWorkerToWorkplaceAffiliateProgramEnabled
                    ),
                    title,
                  });
                }}
              />
            </Stack>

            <Text variant="h2">
              <InternalLink
                to={formattedValueLinkPath}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                    action: WorkplaceReferralAction.REFERRAL_STATS_CLICKED,
                    component: WorkplaceReferralComponent.REFERRAL_DATA_SECTION,
                    variant: getWorkerToWorkplaceVariantName(
                      isWorkerToWorkplaceAffiliateProgramEnabled
                    ),
                    title,
                    link: formattedValueLinkPath,
                  });
                }}
              >
                {formattedValue}
              </InternalLink>
            </Text>
          </Stack>
        }
      />
    </ListItem>
  );
}
