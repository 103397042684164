import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";

import { CopyReferralCode } from "../CopyReferralCode";
import { WorkplaceReferralCard } from "../WorkplaceReferralCard/WorkplaceReferralCard";

interface ShareReferralCodeProps {
  referralCode: string;
}

export function ShareReferralCode({ referralCode }: ShareReferralCodeProps) {
  return (
    <WorkplaceReferralCard>
      <CardContent>
        <Stack spacing={2}>
          <Text bold variant="h4">
            Share Your Referral Code
          </Text>
          <Text>
            Give facility staff your referral code and tell them to use it when they sign up to
            Clipboard.
          </Text>
          <Stack spacing={1}>
            <Text variant="body2">Your Referral Code</Text>
            <CopyReferralCode referralCode={referralCode} />
          </Stack>
        </Stack>
      </CardContent>
    </WorkplaceReferralCard>
  );
}
