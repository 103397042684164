import { Card } from "@mui/material";
import { type ReactNode } from "react";

interface WorkplaceReferralCardsProps {
  children: ReactNode;
}

export function WorkplaceReferralCard(props: WorkplaceReferralCardsProps) {
  const { children } = props;
  return (
    <Card
      variant="outlined"
      sx={{
        background: (theme) => theme.palette.grey[100],
      }}
    >
      {children}
    </Card>
  );
}
