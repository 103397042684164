import validator from "validator";
import { z } from "zod";

export enum ReferrerType {
  WORKER = "WORKER",
  WORKPLACE = "WORKPLACE",
}

export enum RefereeType {
  WORKER = "WORKER",
  WORKPLACE = "WORKPLACE",
}

export enum ReferralConversionStage {
  CONVERTED = "converted",
  UNCONVERTED = "unconverted",
}

export const referralCodesResponseSchema = z.object({
  referralCodes: z.array(
    z.object({
      referrerId: z.string(),
      referrerType: z.nativeEnum(ReferrerType),
      referralCode: z.string(),
    })
  ),
});

export const referralCodeResponseSchema = z.object({
  referrerId: z.string(),
  referrerType: z.nativeEnum(ReferrerType),
  referralCode: z.string(),
});

export const referralStatsRequestSchema = z.object({
  type: z.nativeEnum(RefereeType),
  referrerId: z.string(),
});

export const referralStatsResponseSchema = z.object({
  convertedReferees: z.number(),
  totalEarnings: z.number(),
  unconvertedReferees: z.number(),
});

export const referralsRequestSchema = z.object({
  type: z.nativeEnum(RefereeType),
  referrerId: z.string(),
  conversion: z.string(),
});

export const referralsResponseSchema = z.object({
  data: z.array(
    z.object({
      referralCodeUsed: z.string(),
      referrer: z.object({ type: z.string() }),
      status: z.string(),
      workplace: z.object({
        id: z.string(),
        name: z.string(),
        fullAddress: z.object({ formatted: z.string() }),
        user: z.object({ email: z.string(), name: z.string() }),
      }),
      // FIXME: Update the API to send the amount in minor units(cents) and
      // rename the variable name as 'amountAppliedInCents'(TICKET: CH-25173).
      refereeBonus: z.object({ amountApplied: z.number() }).optional(),
    })
  ),
});

export type ReferralCodeResponse = z.infer<typeof referralCodesResponseSchema>;
export type ReferralCode = ReferralCodeResponse["referralCodes"][0];

export type ReferralStatsRequest = z.infer<typeof referralStatsRequestSchema>;
export type ReferralStatsResponse = z.infer<typeof referralStatsResponseSchema>;

export type ReferralsRequest = z.infer<typeof referralsRequestSchema>;
export type ReferralsResponse = z.infer<typeof referralsResponseSchema>;

export const shareYourProfileFormSchema = z.object({
  email: z.string().refine((value) => validator.isEmail(value), {
    message: "Please provide a valid email address.",
  }),
});
export type ShareYourProfileForm = z.infer<typeof shareYourProfileFormSchema>;

export enum WorkplaceReferralAction {
  BANNER_CLICKED = "BANNER_CLICKED",
  PAGE_VIEWED = "PAGE_VIEWED",
  BACK_BUTTON_CLICKED = "BACK_BUTTON_CLICKED",
  BONUS_BREAKDOWN_CLICKED = "BONUS_BREAKDOWN_CLICKED",
  REFERRAL_CODE_COPIED = "REFERRAL_CODE_COPIED",
  REFERRAL_GUIDE_CLICKED = "REFERRAL_GUIDE_CLICKED",
  REFERRAL_POLICY_CLICKED = "REFERRAL_POLICY_CLICKED",
  REFERRAL_SHIFT_ATTENDANCE_RATE_CLICKED = "REFERRAL_SHIFT_ATTENDANCE_RATE_CLICKED",
  SHARE_PROFILE_CLICKED = "SHARE_PROFILE_CLICKED",
  SHARE_PROFILE_DIALOG_CLOSED = "SHARE_PROFILE_DIALOG_CLOSED",
  PROFILE_SHARED = "PROFILE_SHARED",
  SHARE_REFERRAL_LINK_CLICKED = "SHARE_REFERRAL_LINK_CLICKED",
  SHARE_REFERRAL_LINK_DIALOG_CLOSED = "SHARE_REFERRAL_LINK_DIALOG_CLOSED",
  REFERRAL_LINK_SHARED = "REFERRAL_LINK_SHARED",
  REFERRAL_LINK_COPIED = "REFERRAL_LINK_COPIED",
  REFERRAL_STATS_CLICKED = "REFERRAL_STATS_CLICKED",
  REFERRAL_STATS_TOOLTIP_CLICKED = "REFERRAL_STATS_TOOLTIP_CLICKED",
  REFERRAL_STATS_EMAIL_COPIED = "REFERRAL_STATS_EMAIL_COPIED",
  REFERRAL_STATS_TAB_CLICKED = "REFERRAL_STATS_TAB_CLICKED",
}

export enum WorkplaceReferralActionSource {
  OVERLAY_CLICKED = "OVERLAY_CLICKED",
  BUTTON_CLICKED = "BUTTON_CLICKED",
}

export enum WorkplaceReferralComponent {
  WORKPLACE_REFERRAL_BANNER = "WORKPLACE_REFERRAL_BANNER",
  WORKPLACE_REFERRAL_PAGE = "WORKPLACE_REFERRAL_PAGE",
  WORKPLACE_REFERRAL_STATS_PAGE = "WORKPLACE_REFERRAL_STATS_PAGE",
  BONUS_BREAKDOWN_SECTION = "BONUS_BREAKDOWN_SECTION",
  COPY_REFERRAL_CODE_SECTION = "COPY_REFERRAL_CODE_SECTION",
  REFERRAL_GUIDE_SECTION = "REFERRAL_GUIDE_SECTION",
  REFERRAL_POLICY_SECTION = "REFERRAL_POLICY_SECTION",
  PROMOTE_PROFILE_SECTION = "PROMOTE_PROFILE_SECTION",
  REFERRAL_DATA_SECTION = "REFERRAL_DATA_SECTION",
  SHARE_REFERRAL_LINK_SECTION = "SHARE_REFERRAL_LINK_SECTION",
  SHARE_REFERRAL_LINK_DIALOG = "SHARE_REFERRAL_LINK_DIALOG",
  SHARE_PROFILE_DIALOG = "SHARE_PROFILE_DIALOG",
  WORKPLACE_REFERRAL_STATS_LIST_ITEM = "WORKPLACE_REFERRAL_STATS_LIST_ITEM",
  WORKPLACE_REFERRAL_STATS_TAB_ITEM = "WORKPLACE_REFERRAL_STATS_TAB_ITEM",
}
