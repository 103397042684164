import { Text, useModalState } from "@clipboard-health/ui-react";
import { Button, CardActions, CardContent, List, ListItem, Stack } from "@mui/material";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import qs from "query-string";
import { useLocation } from "react-router-dom";

import { getWorkplaceSignUpWithReferralCodePath } from "../../api/utils";
import { useWorkerToWorkplaceAffiliateExperiment } from "../../hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "../../types";
import { getWorkerToWorkplaceVariantName } from "../../utils/getWorkerToWorkplaceVariantName";
import { WorkplaceReferralCard } from "../WorkplaceReferralCard/WorkplaceReferralCard";
import { shareReferralLink } from "./shareLink";
import { ShareReferralLinkDialog } from "./ShareReferralLinkDialog";

interface ShareReferralLinkCardProps {
  referralCode: string;
}

export function ShareReferralLinkCard(props: ShareReferralLinkCardProps) {
  const { referralCode } = props;
  const { search } = useLocation();
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  useLogEffect(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
    action: WorkplaceReferralAction.PAGE_VIEWED,
    component: WorkplaceReferralComponent.WORKPLACE_REFERRAL_PAGE,
    variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
    referralCode,
    pageQueryParams: qs.parse(search),
  });
  const shareReferralLinkModalState = useModalState();
  return (
    <WorkplaceReferralCard>
      <CardContent>
        <Stack spacing={1}>
          {isWorkerToWorkplaceAffiliateProgramEnabled ? (
            <>
              <Text bold variant="h4">
                How It Works
              </Text>
              <Text>
                To complete a referral, facilities will have to sign up to Clipboard using your
                personalized link and have their first shift verified.
              </Text>
            </>
          ) : (
            <>
              <Text bold variant="h4">
                Share Your Referral Link
              </Text>
              <List dense sx={{ paddingBottom: 0 }}>
                <ListItem>
                  <Text>
                    1. <b>Text or email</b> your link to facility staff.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    2. <b>Copy</b> your link so that you can post it on other platforms, such as
                    social media.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    3. <b>Generate a QR code</b> for a facility staff member to scan directly with
                    their phone.
                  </Text>
                </ListItem>
              </List>
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant="contained"
          size="small"
          onClick={async () => {
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
              action: WorkplaceReferralAction.SHARE_REFERRAL_LINK_CLICKED,
              component: WorkplaceReferralComponent.SHARE_REFERRAL_LINK_SECTION,
              variant: getWorkerToWorkplaceVariantName(isWorkerToWorkplaceAffiliateProgramEnabled),
              referralCode,
            });
            if (isWorkerToWorkplaceAffiliateProgramEnabled) {
              const signUpReferralCodeLink = `${
                environmentConfig.REACT_APP_ADMIN_WEB_APP_URL
              }${getWorkplaceSignUpWithReferralCodePath(referralCode)}`;
              await shareReferralLink({
                signUpReferralCodeLink,
                referralCode,
                variant: getWorkerToWorkplaceVariantName(
                  isWorkerToWorkplaceAffiliateProgramEnabled
                ),
              });
            } else {
              shareReferralLinkModalState.openModal();
            }
          }}
        >
          {isWorkerToWorkplaceAffiliateProgramEnabled ? "Share Link With Facilities" : "Share Link"}
        </Button>
      </CardActions>
      <ShareReferralLinkDialog
        modalState={shareReferralLinkModalState}
        referralCode={referralCode}
      />
    </WorkplaceReferralCard>
  );
}
